import React from 'react';
import { PanelProps } from '@grafana/data';
import { cx, css } from '@emotion/css';
import { CustomScrollbar, useStyles2, useTheme2 } from '@grafana/ui';
import { Tree as ProcessTree, useProps as useProcessTreeProps } from '@isovalent/grafana-process-tree';

import pluginMeta from './plugin.json';

export const ProcessAncestry = ({ data }: PanelProps) => {
  const theme = useTheme2();
  const styles = useInternalStyles();
  const processTreeProps = useProcessTreeProps(data);
  return (
    <CustomScrollbar>
      <ProcessTree
        {...processTreeProps}
        className={cx(styles.wrapper)}
        hideIpsInKnownGroups={true}
        hideIpsInUnknownGroup={false}
        iconsPrefix={`/public/plugins/${pluginMeta.id}/static`}
        isEmbedded={true}
        isDarkThemeEnabled={theme.isDark}
      />
    </CustomScrollbar>
  );
};

const useInternalStyles = () =>
  useStyles2(() => {
    return {
      wrapper: css`
        position: relative;
      `,
    };
  });
